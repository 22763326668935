.blueTheme,
.themeCode01 {
	--clr-primary: #8D8DB2 !important;
	--clr-primary-light: #B6B6D6 !important;
	--clr-primary-dark: #383842 !important;
	--clr-secondary: #F4F4FF !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
}

.greenTheme,
.themeCode02 {
	--clr-primary: #8DB2AB !important;
	--clr-primary-light: #B6D6D1 !important;
	--clr-primary-dark: #384042 !important;
	--clr-secondary: #F4FAFF !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
}

.yellowTheme,
.themeCode03 {
	--clr-primary: #B2B08D !important;
	--clr-primary-light: #D6D5B6 !important;
	--clr-primary-dark: #3F4238 !important;
	--clr-secondary: #F9FFF4 !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
}

.orangeTheme,
.themeCode04 {
	--clr-primary: rgb(237, 162, 138) !important;
	--clr-primary-light: #C5979E !important;
	--clr-primary-dark: #3A3331 !important;
	--clr-secondary: #FFD7B6 !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
}
.ps-sidebar-container {
	background-color: var(--clr-secondary) !important;
}

.ps-menu-button {
	border-radius: 5px;
}
.ps-menu-button.active,
.ps-menu-button:hover {
	background-color: var(--clr-primary) !important;
	color: var(--clr-white-01) !important;
}
.ps-menu-button {
	color: var(--clr-primary-dark) !important;
}
.ps-broken.ps-toggled {
	top: var(--top-nav-height) !important;
}

.ps-active {
	background-color: var(--clr-primary) !important;
	color: var(--clr-white-01) !important;
	border-radius: 5px;
}