.appointment-details .topic-name {
  font-weight: bold;
}

.appointment-details .appointment-icons {
  margin-right: 10px;
}
.coach-description {
  color: var(--clr-primary);
}
.profile-wrap {
  padding-right: 0px;
}
.skill-detail .title {
  font-weight: bold;
}
.skill-detail .skill-primary {
  color: var(--clr-primary);
  font-size: 18px;
}
.appointment-details .btn-add {
  width: 100%;
}

/* Aghro kit Ui Css */
/* ._3Sxu7 {
  overflow: hidden scroll;
  display: flex !important;
  flex: none !important;
} */

/* Progress bar css */
.progress {
  width: 100% !important;
  --bs-progress-bar-bg: var(--clr-primary) !important
}