/* ChatPage.css */

/* Custom style for ConversationList */
.conversation-list {
    background-color: #f0f0f0;
    /* Set your desired background color */
  }
  .str-chat__file-input-container{
    display: none !important;
  }
  
  /* Custom style for individual conversations */
  .conversation-item {
    align-items: center;
    border-radius: 16px;
    box-sizing: border-box;
    display: flex;
    height: 74px;
    margin: 4px 8px;
    padding: 12px;
    position: relative;
    gap: 12px;
    /* Set your desired border color */
  }

  .detail{
    display: flex;
    flex-direction: column;
    justify-content: space-around
  }
  
  /* Custom style for the selected conversation */
  .conversation-item.selected {
    background-color:#F5D9D5;
    /* Set your desired background color for selected conversation */
  }
  .str-chat__channel-list-react .str-chat__channel-list-messenger-react .str-chat__channel-list-messenger-react__main{

    background-color: #FEF9F5 !important;
  }
  
  .cui-avatar {
  
    background-color: #D6C5C8;
  
  }
  .cui-message-base-primary .cui-message-base-content {
    background-color: #B18C93;
  }
  .thumbnail-image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .title{
    font-size:16px;
    font-weight: 500;  
  }

  .titlepading{
    padding-top: 12px;
  }

  .count{
    width: 30px;
    height: 30px;
border: 2px solid white;
background-color: #B18C93;   
border-radius: 50%;
text-align: center;
  }
  /* .borderdiv{
    border: 2px solid  #B18C93;
    border-radius: 10px;
  } */

  .str-chat__list , .str-chat__channel-header ,.str-chat__message-input,.str-chat__list-notifications{

    background-color: #FEF9F5 !important;

  }

  .heightclass{
    height: 80vh;
  }

 
  .str-chat__list::-webkit-scrollbar { width: 20px;height: 50px; }

  /* .str-chat__list::-webkit-scrollbar-track { background:#B18C93 } */

  .str-chat__list::-webkit-scrollbar-thumb {
    border: 5px solid var(--smoky-black);
    background: hsla(0, 0%, 100%, 0.1);
    border-radius: 20px;
    box-shadow: inset 1px 1px 0 hsla(0, 0%, 100%, 0.11),
                inset -1px -1px 0 hsla(0, 0%, 100%, 0.11);
  }

  .str-chat__list::-webkit-scrollbar-thumb:hover { background: hsla(0, 0%, 100%, 0.15); }

  .str-chat__list::-webkit-scrollbar-button { height: 5px; }

  /* .title.selected{
    color: var(--cui-primary-color95);
  } */
  
  /* .cui-recorder {
    display: none;
  }
  .icon-container {
    display: none;
  } */