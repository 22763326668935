/* Global Classes */
.flx-center-xy {
	display: flex;
	justify-content: center;
	align-items: center;
}
.text-danger {
	color: #7F4850 !important;
}
.invalid-feedback {
	color: #7F4850 !important;
}
.form-control.is-invalid {
	border-color: #7F4850 !important;
}
.fieldError {
	color: #7F4850 !important;
}