.login-container {
	height: 97vh;
}
.login-container > .row {
	position: relative;
	top: 20%;
}
.login-wrap {
	padding: 3rem;
	border-radius: 10px;
	/* border: 1px solid var(--bs-border-color); */
	/* box-shadow: 0px 0px 4px 1px var(--bs-border-color); */
	background-color: var(--clr-primary-light);
}
.login-wrap .frm-group {
	margin-bottom: 1rem;
}
.login-wrap .action-lnk {
	color: var(--clr-primary);
}
.login-wrap .action-lnk > svg.icon {
	color: var(--clr-primary);
	margin-left: 5px;
}
.login-wrap {
	background-color: var(--clr-primary-light);
}
.login-header {
	text-align: center;
}
.login-header h3 {
	font-variant: var(--Gilroy-Bold);
}
.login-btn {
	width: 100%;
	padding: 10px !important;
}
.modal-width {
	max-width: 50% !important;
}
.modal-header,
.modal-footer {
	justify-content: center !important;
}
.terms-description {
	max-height: 56vh;
	overflow-x: hidden;
	overflow-y: auto;
}
.terms-para {
	padding-top: 1em;
}

.login-wrap .bottom-text {
	font-size: 0.8rem;
	text-align: center;
}

.login-wrap .frm-group {
	margin-bottom: 1.2rem;
}
.login-wrap .form-label {
	font-family: 'Gilroy-Bold';
	margin-bottom: 0.4rem;
}
.login-wrap .form-control,
.login-wrap .form-control:focus {
	background-color: var(--clr-transparent);
	border-color: var(--clr-grey-03);
}
.login-wrap .form-control::placeholder {
	color: var(--clr-grey-02);
}
.login-wrap .form-select,
.login-wrap .form-select:focus {
	background-color: var(--clr-transparent);
	border-color: var(--clr-grey-03);
}
.login-wrap .frm-heading {
	text-align: center;
}
.login-wrap .frm-subheading {
	text-align: center;
}
.login-wrap .action-lnk {
	color: var(--clr-primary);
}
.login-wrap .action-lnk > svg.icon {
	color: var(--clr-primary);
	margin-left: 5px;
}

.login-wrap .input-group-text {
	background: none;
	border-left: none;
}