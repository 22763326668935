/* Normalize/Reset css */
:root {
	--clr-primary: #B18C93;
	--clr-primary-light: #FEF9F5;
	--clr-primary-light-02: #D6C5C8;
	--clr-primary-dark: #423A38;
	--clr-secondary: #FEF9F5;
	--clr-transparent: transparent;
	--clr-white-01: #FFFFFF;
	--clr-black-01: #000000;
	--clr-grey-01: #433B39;
	--clr-grey-02: #8E8988;
	--clr-grey-03: #CACACA;
	--clr-agborder: #F5D9D5;
	--clr-disable-icon: #ccc;
	--top-nav-height: 100px;
	--clr-border:#B28D94;
	--aside-width: 20%;
	--main-width: 80%;
}
.blue-theme,
.themeCode01 {
	--clr-primary: #8D8DB2 !important;
	--clr-primary-light: #B6B6D6 !important;
	--clr-primary-dark: #383842 !important;
	--clr-secondary: #F4F4FF !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
	--clr-agborder: #B6B6D6 !important;
	--clr-disable-icon: #8D8DB2 !important;
	--top-nav-height: 100px;
	--aside-width: 20%;
	--main-width: 80%;
}
.greenTheme,
.themeCode02 {
	--clr-primary: #8DB2AB !important;
	--clr-primary-light: #B6D6D1 !important;
	--clr-primary-dark: #384042 !important;
	--clr-secondary: #F4FAFF !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
	--clr-disable-icon: #8DB2AB !important;
}

.yellowTheme,
.themeCode03 {
	--clr-primary: #B2B08D !important;
	--clr-primary-light: #D6D5B6 !important;
	--clr-primary-dark: #3F4238 !important;
	--clr-secondary: #F9FFF4 !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
	--clr-disable-icon: #B2B08D !important;
}

.orangeTheme,
.themeCode04 {
	--clr-primary: rgb(237, 162, 138) !important;
	--clr-primary-light: #C5979E !important;
	--clr-primary-dark: #3A3331 !important;
	--clr-secondary: #FFD7B6 !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
}
::selection {
	background-color: var(--clr-primary);
	color: var(--clr-white-01);
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

/* Font Families */
/* Gilroy */
@font-face {
	font-family: 'Gilroy-Heavy';
	src: local('Gilroy-Heavy'), url(../assets/fonts/Gilroy/Gilroy-Heavy.ttf) format('truetype');
}

@font-face {
	font-family: 'Gilroy-Bold';
	src: local('Gilroy-Bold'), url(../assets/fonts/Gilroy/Gilroy-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'Gilroy-Medium';
	src: local('Gilroy-Medium'), url(../assets/fonts/Gilroy/Gilroy-Medium.ttf) format('truetype');
}

@font-face {
	font-family: 'Gilroy-Regular';
	src: local('Gilroy-Regular'), url(../assets/fonts/Gilroy/Gilroy-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'Gilroy-Light';
	src: local('Gilroy-Light'), url(../assets/fonts/Gilroy/Gilroy-Light.ttf) format('truetype');
}

/* Roboto */
@font-face {
	font-family: 'Roboto-Regular';
	src: local('Roboto-Regular'), url(../assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto-Thin';
	src: local('Roboto-Thin'), url(../assets/fonts/Roboto/Roboto-Thin.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto-ThinItalic';
	src: local('Roboto-ThinItalic'), url(../assets/fonts/Roboto/Roboto-ThinItalic.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto-MediumItalic';
	src: local('Roboto-MediumItalic'), url(../assets/fonts/Roboto/Roboto-MediumItalic.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto-Medium';
	src: local('Roboto-Medium'), url(../assets/fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto-LightItalic';
	src: local('Roboto-LightItalic'), url(../assets/fonts/Roboto/Roboto-LightItalic.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto-Light';
	src: local('Roboto-Light'), url(../assets/fonts/Roboto/Roboto-Light.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto-Italic';
	src: local('Roboto-Italic'), url(../assets/fonts/Roboto/Roboto-Italic.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto-BoldItalic';
	src: local('Roboto-BoldItalic'), url(../assets/fonts/Roboto/Roboto-BoldItalic.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto-Bold';
	src: local('Roboto-Bold'), url(../assets/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto-BlackItalic';
	src: local('Roboto-BlackItalic'), url(../assets/fonts/Roboto/Roboto-BlackItalic.ttf) format('truetype');
}
@font-face {
	font-family: 'Roboto-Black';
	src: local('Roboto-Black'), url(../assets/fonts/Roboto/Roboto-Black.ttf) format('truetype');
}

body {
	margin: 0;
	font-family: 'Gilroy-Regular', 'Roboto-Regular';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}