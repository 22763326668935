

.str-video {
    background-color: #272a30;
    color: #ffffff;
    height: 100dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 0;
    max-width: 100%;
  }
  
  .video-container{
    width: 100%;
  }

  .errorContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  