.horizontal-stepper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.text-align-start{
  text-align: start;
}

.rdp-table {
  margin: 0;
  /* max-width: calc(var(--rdp-cell-size) * 7); */
  border-collapse: collapse;
  width: 100% !important;
  max-width: 100% !important;
}

.rdp-months {
  display: block !important;
}

rdp-cell {
  width: var(--rdp-cell-size);
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 0px 10px !important;
  text-align: center;
}

.step {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  position: relative;

  flex-grow: 1;
  /* Adjust spacing between steps */
}

.step-icon {
  height: 24px;
  display: flex;
}

.line {
  width: 100%;
  /* Adjust the length of the line */
  height: 3px;
  /* Adjust the thickness of the line */
  background-color: var(--clr-primary-light);
  /* Adjust the color of the line */
  position: absolute;
  left: calc(50%);
  /* Adjust the offset to center the line with the circle icon */
  transform: translate(-50%, -50%);
}

.line-filled {
  width: 100%;
  /* Adjust the length of the line */
  height: 3px;
  /* Adjust the thickness of the line */
  background-color: var(--clr-primary);
  /* Adjust the color of the line */
  position: absolute;
  left: calc(50%);
  /* Adjust the offset to center the line with the circle icon */
  transform: translate(-50%, -50%);
}

.step_active {
  color: var(--clr-primary);
  /* Adjust the color for the active step */
  font-weight: bold;
}

.step-name {
  padding-left: 5px;
  font-family: 'Gilroy-Medium';
  padding-top: 2px;
}

.selected-slot {
  border: 1px solid var(--clr-border);
  /* Add a border */
  border-radius: 19px;
  display: inline-flex;
  flex-direction: row;
  padding: 4px 10px;
  margin: 5px 10px;
  background-color: var(--clr-primary-light);
}

.skill-container {
  margin-inline: 20px;
}
.skill-container-coach {
  margin-inline: 5px;
  /* display: flex; */
}

.skill-card {
  border: 1px solid var(--clr-border);
  /* Add a border */
  display: inline-flex;
  flex-direction: row;
  box-shadow: 0em 0.1em 0.1em rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 0.7em;
  padding: 15px;
  margin: 10px;
  cursor: pointer;
}

.skill-card-no-click {
  border: 1px solid var(--clr-border);
  /* Add a border */
  display: inline-flex;
  flex-direction: row;
  box-shadow: 0em 0.1em 0.1em rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 0.7em;
  padding: 12px;
  margin: 10px;
}

.skill-card-selected {
  border: 1px solid var(--clr-border);
  /* Add a border */
  display: inline-flex;
  flex-direction: row;
  box-shadow: 0em 0.1em 0.1em rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 0.7em;
  background-color: var(--clr-primary);
  color: #dddddd;
  padding: 12px;
  margin: 10px;

}

.skill-card-coach {
  border: 1px solid var(--clr-border);
  display: flex;
  flex-direction: row;
  box-shadow: 0em 0.1em 0.1em rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 0.7em;
  flex: 1;
  /* margin: 3px; */

  padding: 8px;


}
.skill-card-coach-selected {
  border: 1px solid var(--clr-border);
  /* Add a border */
  display: flex;
  flex-direction: row;
  box-shadow: 0em 0.1em 0.1em rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 0.7em;
  background-color: var(--clr-primary);
  color: #dddddd;
  padding: 8px;
  flex: 1;

}

.coach-card {
  border: 1px solid var(--clr-border);
  box-shadow: 0em 0.1em 0.1em rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 0.7em;
  padding: 12px;
  display: flex;
  margin: 10px;

}
.radio-align {
  display: flex;
  align-self: center;
  justify-content: center;
}

.skill-detail {
  padding: 7px;
  flex: 1;
}

.header-text {
  font-size: large;
  font-family: 'Gilroy-Regular';
  font-weight: bold;
  ;
}

/* .profile-wrap {
  display: flex;
} */
.thumbnail-image {
  width: 60px;
  aspect-ratio: 1;
  object-fit: contain;
}

.calender-css {
  background-color: var(--clr-primary-light);
  width: 200px;
}

.calender-conainer {
  display: flex;
}

.select-time-slot {
  width: 50%;
}

.selected-slot-id {
  cursor: pointer;
  background-color: var(--clr-primary);
  color: white;
  border: 1px solid var(--clr-border);
  border-radius: 19px;
  display: inline-flex;
  flex-direction: row;
  padding: 4px 10px;
  margin: 5px 10px;
  cursor: pointer;
}

.skill-card-time-management {
  border: 1px solid var(--clr-border);
  /* Add a border */
  flex-direction: row;
  box-shadow: 0em 0.1em 0.1em rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 0.7em;
  padding: 12px;
  margin: 10px;
}

.justify-content-center {
  justify-content: center !important;
  margin-inline: 10px;
}

.unavailable-slots {
  /* Add a border */
  border-radius: 19px;
  display: inline-flex;
  flex-direction: row;
  padding: 4px 10px;
  margin: 5px 10px;
  border: 1px dotted #eae7e7;
  color: #ccc;
  background: none;
}

.my-selected:not([disabled]) {
  font-weight: bold;
  background-color: var(--clr-transparent) !important;
  border: 1px solid var(--clr-primary);
  color: black;
}
.my-selected:hover:not([disabled]) {
  border-color: var(--clr-primary);
  background-color: var(--clr-primary) !important;
  color: var(--clr-primary-light)
}

.my-available:not([disabled]) {
  font-weight: bold;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-decoration-color: var(--clr-primary);
  text-underline-offset: 3px;
}

.my-available:not([disabled]):hover:after {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: var(--clr-primary);
  text-underline-offset: 3px;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  /* color: var(--clr-primary-light) !important;
  background-color: var(--clr-primary) !important; */
  background-color: var(--clr-transparent) !important;
  border: 1px solid var(--clr-primary);
  color: black;
}

/*Coach Details CSS*/
.profile-detail .card-text {
  margin-bottom: 0;
}
.profile-detail .card {
  /* -webkit-box-shadow: 0 8px 6px -6px #cdcdcd;
  -moz-box-shadow: 0 8px 6px -6px #cdcdcd;
  box-shadow: 0 8px 6px -6px #cdcdcd; */
  border: none;
  background-color: var(--clr-transparent);

}
.profile-detail .card-block {
  padding: 10px;
}
.profile-detail .nav-tabs .nav-link {
  color: var(--clr-primary-dark);
  font-weight: bold;

}
/* .nav-tabs {
  border-bottom: 1px solid var(--clr-primary);
} */

.nav-tabs {

  --bs-nav-tabs-border-color: var(--clr-agborder) !important;


}
.profile-detail .nav-tabs .nav-link.active {
  color: var(--clr-primary);
  border: none;
  border-bottom: 3px solid var(--clr-primary);
  background: none;
}
.profile-detail .nav-tabs .nav-link:hover {
  border: none;
}
.profile-detail .nav-tabs .nav-link:focus-visible {
  box-shadow: none;
}
.profile-detail .tab-pane {
  color: #000;
}
.profile-detail .tab-pane li,
.profile-detail .tab-pane p {
  font-weight: 300;
}

.profile-review .card {
  padding-top: 10px;
  border: none;
}
.profile-review .review-description {
  margin-bottom: 5px;
}
.profile-review a {
  color: var(--clr-primary)
}

.about-container .about-label {
  font-weight: bold !important;
}
.about-container .about-label span {
  font-weight: 100 !important;
}

.profile-review {
  max-height: 750px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.profile-view-all {
  color: var(--clr-primary);
}

.react-day-picker button:focus:not(:focus-visible) {
  color: black;
  /* background-color: var(--clr-primary) */
}

.profile-info{
  display: flex;
align-items: center;
justify-content: space-between;}

.aboutself{
  font-size: 14px;
  overflow: hidden;
  /* height: 80px; */
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  
}

.flexCenter{
  display:flex;
  justify-content: center;
}
/*Ends*/