.loader-overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(170, 170, 170, 0.2);
	z-index: 1000;
}