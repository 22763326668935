.defaultTheme {
	--clr-primary: #B18C93;
	--clr-primary-light: #FEF9F5;
	--clr-primary-light-02: #D6C5C8;
	--clr-primary-dark: #423A38;
	--clr-secondary: #FEF9F5;
	--clr-transparent: transparent;
	--clr-white-01: #FFFFFF;
	--clr-black-01: #000000;
	--clr-grey-01: #433B39;
	--clr-grey-02: #8E8988;
	--clr-grey-03: #CACACA;
	--clr-agborder: #F5D9D5;
	--clr-disable-icon: #ccc;
	--top-nav-height: 100px;
	--aside-width: 20%;
	--main-width: 80%;
}

.blueTheme,
.themeCode01 {
	--clr-primary: #8D8DB2 !important;
	--clr-primary-light: #B6B6D6 !important;
	--clr-primary-dark: #383842 !important;
	--clr-secondary: #F4F4FF !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
	--clr-agborder: ##F4F4FF !important;
	--clr-disable-icon: #8D8DB2 !important;
}
.greenTheme,
.themeCode02 {
	--clr-primary: #8DB2AB !important;
	--clr-primary-light: #B6D6D1 !important;
	--clr-primary-dark: #384042 !important;
	--clr-secondary: #F4FAFF !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
	--clr-agborder: #F4F4FF !important;
	--clr-disable-icon: #8DB2AB !important;
}

.yellowTheme,
.themeCode03 {
	--clr-primary: #B2B08D !important;
	--clr-primary-light: #D6D5B6 !important;
	--clr-primary-dark: #3F4238 !important;
	--clr-secondary: #F9FFF4 !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
	--clr-agborder: #F9FFF4 !important;
	--clr-disable-icon: #B2B08D !important;
}


.orangeTheme,
.themeCode04 {
	--clr-primary: rgb(237, 162, 138) !important;
	--clr-primary-light: #C5979E !important;
	--clr-primary-dark: #3A3331 !important;
	--clr-secondary: #FFD7B6 !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
	--clr-agborder: #FFD7B6 !important;
}

.protected-layout {
	background-color: var(--clr-secondary);
}

.main-wrapper {
	display: flex;
}

/* Aside */
.main-wrapper .view-aside {
	transition: width 0.3s linear;
}

/* Sidenav */
.side-header {
	position: relative;
	flex-basis: var(--top-nav-height);
	border-bottom: 1px solid var(--clr-primary);
}
.side-header .navbar-brand {
	text-align: center;
}
.side-header .navbar-brand > img {
	width: 9rem;
}
.sidenav-toggler {
	position: absolute;
	cursor: pointer;
	left: -1rem;
	top: 50%;
	transform: translateY(-50%);
}
.sidenav-collapsed .sidenav-toggler {
	left: 10px;
}

/* Content */
.main-wrapper .view-main {
	flex: 1;
	transition: all 0.3s linear;
}
.main-wrapper.sidenav-broken .view-main {
	width: 100%;
}

.view-main{
	display: flex;
	flex-direction: column;
}


/* Header */
.view-header {
	position: sticky;
	top: 0;
	height: var(--top-nav-height);
	z-index: 2;
	border-bottom: 1px solid var(--clr-primary);
	background-color: var(--clr-secondary);
	;
}
.view-header .header-title {
	font-family: 'Gilroy-Bold';
	margin-bottom: 0;
}
.view-header .view-profile {
	margin-left: auto;
}
.view-header .view-profile .dropdown-toggle {
	display: flex;
	align-items: center;
}
.view-header .view-profile .profile-wrap {
	display: flex;
}
.view-header .view-profile .thumbnail-image {
	width: 70px;
	height: 70px;
	aspect-ratio: 1;
	object-fit: contain;
	border-radius: 50%;

}

.thumbnail-image{

	width: 60px !important;
	height: 60px !important;

}

.drop-down-list {
	width: 100%;
}

.view-header .view-profile .profile-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 10px;
}
.view-header .view-profile .profile-info .subtitle {
	font-size: 0.8rem;
}

/* Footer */
.main-wrapper .view-sidenav-footer {
	margin-top: auto;
	color: var(--clr-primary-light);
	text-align: center;
}
.main-wrapper .view-sidenav-footer .btn-link {
	text-decoration: none;
}
.main-wrapper .view-sidenav-footer .btn-text {
	color: var(--clr-primary);
	margin-left: 5px;
}

/* Article */
.view-article {
	padding: 2rem 0.5rem 0;
}

.view-article {
 display: flex;
 flex: 1;
 
}


/* Main Content */
.main-content {
	padding-bottom: 1rem;
}
.main-container-flex{
	flex: 1;
	flex-direction: column;
	display: flex;
}
.main-content .page-heading {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.5rem;
}
.main-content .page-heading .hd-txt {
	font-family: 'Gilroy-Bold';


}
.back-continue {
	margin-top: 8px;
}

/* Form */
.form-01-wrap .frm-group {
	margin-bottom: 1.2rem;
}
.form-01-wrap .form-label {
	font-family: 'Gilroy-Bold';
	margin-bottom: 0.4rem;
}
.form-01-wrap .form-control,
.form-01-wrap .form-control:focus {
	background-color: var(--clr-transparent);
	border-color: var(--clr-primary);

}
.form-01-wrap .form-control::placeholder {
	color: var(--clr-grey-02);
}
.form-01-wrap .form-select,
.form-01-wrap .form-select:focus {
	background-color: var(--clr-transparent);
	border-color: var(--clr-primary);
}
.form-01-wrap .frm-btn-grp {
	margin-bottom: 1rem;
}

/* Tabl Panel */

.tab-thm-01 .nav-tabs {
	border-bottom: 1px solid var(--clr-primary);
}
.tab-thm-01 .nav-tabs .nav-link {
	background-color: var(--clr-transparent);
	font-family: "Gilroy-Medium";
	color: var(--clr-primary);
	text-align: center;
	border: none;
}
.tab-thm-01 .nav-tabs .nav-link.active {
	background-color: var(--clr-primary);
	color: var(--clr-white-01);
	font-family: 'Gilroy-Bold';
	border-bottom: 2px solid var(--clr-primary);
}

/* Details Page */
.details-wrap .detail-row {
	margin-bottom: 1.5rem;
}
.details-wrap .detail-row .drw-label {
	font-family: "Gilroy-Bold";
}
.details-wrap .detail-row .drw-pill {
	border: 1px solid var(--clr-primary);
	border-radius: 5px;
	padding: 5px 10px;
}

.form-01-wrap .float-div {
	border: 1px solid var(--clr-primary);
	/* Add a border */
	/* box-shadow: 0em 0.31em 0.9em rgba(0, 0, 0, 0.2); */
	border-radius: 10px;
	margin-top: 0.7em;
}

.form-01-wrap .float-inside-div {
	padding: 1.1em;
}

/* for custom select */
#errorObject {
	color: #7F4850;
	font-size: 0.875em;
	margin-top: 0.25rem
}

.upload-div {
	align-items: center;
}
.upload-div .item {
	margin-left: 0.5em;
}

.datePicker {
	width: 100%;
	background-color: var(--clr-transparent);

	border-radius: 5px;
	height: 38px;
	padding-left: 0px;
	/* border-color: var(--clr-primary); */
}


.datePickerWrapper {
	width: 100%;
}

.datePickeradmin {
	/* width: 100%; */
	background-color: var(--clr-transparent);

	border-radius: 5px;
	height: 38px;
	padding: 12px;
	text-align: center !important;
}

/* @media (max-width: 1024px) {
	.custom-date-picker {
		width: 100%;
		/* Set the width to 100% for mobile screens */
/* }
} */

/* Custom CSS for medium screens (e.g., tablet landscape) */
/* @media (min-width: 600px) and (max-width: 1024px) {
	.custom-date-picker {
		width: 80%;
		/* Adjust the width for tablet views */
/* }
}  */

.btn-report {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}
.report-row {
	gap: 10px;
}



.topic-appointment {
	border: 1px solid var(--clr-primary);
	/* Add a border */
	box-shadow: 0em 0.31em 0.9em rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	padding: 10px;
	margin: 10px;
	color: black;
	cursor: pointer;
}

.coach-card {
	border: 1px solid var(--clr-primary);
	/* Add a border */
	box-shadow: 0em 0.31em 0.9em rgba(0, 0, 0, 0.2);
	border-radius: 10px;

}

.quick-action {
	border: 1px solid var(--clr-primary);
	/* Add a border */
	/* box-shadow: 0em 0.31em 0.9em rgba(0, 0, 0, 0.2); */
	border-radius: 10px;
	padding: 1% 0% 3% 0%;
}

.inner-card {
	/* border: 1px solid #dddddd; */
	/* Add a border */
	/* box-shadow: 0em 0.31em 0.9em rgba(0, 0, 0, 0.2); */
	border-radius: 10px;
	padding: 2% 2%;
	font-family: var(--bs-body-font-family);
	font-size: var(--bs-body-font-size);
	font-weight: var(--bs-body-font-weight);
	line-height: var(--bs-body-line-height);
	color: var(--bs-body-color);
}

.inner-card-border {
	border: 1px solid var(--clr-border);
	/* Add a border */
	/* box-shadow: 0em 0.31em 0.9em rgba(0, 0, 0, 0.2); */
	border-radius: 10px;
	padding: 10px;
	font-family: var(--bs-body-font-family);
	font-size: var(--bs-body-font-size);
	font-weight: var(--bs-body-font-weight);
	line-height: var(--bs-body-line-height);
	color: var(--bs-body-color);
	align-items: flex-start;
	gap: 7px;

}

.inner-card-session {
	border: 1px solid var(--clr-primary);
	border-radius: 10px;
	background-color: var(--clr-primary);
	padding: 10px;
	color: var(--clr-white-01);
	width: 169px !important;
	height: 116px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}




.clickable-div {
	cursor: pointer;
}
.title-name {

	font-size: 18px;
	font-weight: 500;

}

.full-name {
	/* font-family: 'Poppins'; */
	font-size: 16px;
	font-weight: 400;

}

/* Additional Styles for Icon and Status */
.icon-status {
	/* font-family: 'Poppins'; */
	font-size: 14px;
	font-weight: 400;

}


.main-content .page-size-wrap {
	position: relative;
	bottom: 7%;
}
.main-content .page-size {
	position: absolute;
	left: 1%;
}

.grid_action {
	text-align: center;
	padding: 10px;
}
/* .ag-theme-alpine {
	background-color: var(--clr-primary);
} */
.ag-root,
.ag-cell {
	background-color: var(--clr-primary-light);
	border: 1px solid var(--clr-agborder);

}
.ag-theme-alpine .ag-header {
	background-color: var(--clr-primary-light-02);
}
.ag-paging-panel {
	background-color: var(--clr-primary-light);
	border: 1px solid var(--clr-agborder);
}

.main-content .page-size-wrap {
	position: relative;
	bottom: 7%;
}
.main-content .page-size {
	position: absolute;
	left: 1%;
}
#page-size {
	margin-left: 10px;
}

.grid_action {
	text-align: center;
	padding: 10px;
}
/* .ag-theme-alpine {
	background-color: var(--clr-primary);
} */
.ag-theme-alpine .ag-root,
.ag-cell {
	background-color: var(--clr-primary-light);

}
.ag-theme-alpine .ag-header {
	background-color: var(--clr-primary);
}
.ag-header-cell {
	color: var(--clr-white-01);
}
.ag-paging-panel {
	background-color: var(--clr-primary-light);
	color: var(--clr-white-01);
	border: 1px solid var(--clr-agborder) !important;
}


.form-control:focus {
	border-color: none !important;
	outline: 0;
	box-shadow: none !important;
}

.sidebar-toggle-btn {
	display: none;
}

.css-1qhqytf .ps-sidebar-container {
	overflow-y: hidden;
}
/* Media query */
@media only screen and (max-width: 992px) {
	.main-wrapper {
		display: flex !important;
		flex-direction: column !important;
	}


	.css-1qhqytf.ps-broken {
		position: relative !important;
		height: 100%;
		top: 0px;
		z-index: 100;
		left: 0 !important;
	}
	.top-nav-close {
		height: 90px !important;
		transition: all 0.5s ease linear !important;
	}
	.top-nav-open {
		height: 100% !important;
		transition: all 0.5s ease linear !important;
	}
	.sidebar-toggle-btn {
		display: block;
		position: absolute;
		top: 40px;
		width: 34px;
		z-index: 100;
		left: 24px;
	}
	.main-wrapper .view-sidenav-footer {
		text-align: left;
		padding-left: 20px
	}
}


/* View ORG Css starts here */

.view-org-table td {
	background-color: transparent !important;
}

/* View org Css ends here */

/* .myGrid .ag-row {

	border: 1px solid var(--clr-agborder);

} */

[class*=ag-theme-] {
	--ag-border-color: none !important;
}
.ag-theme-alpine .ag-row {
	border: 1px solid var(--clr-agborder) !important;
}


.ag-header-cell-sortable .ag-header-cell-label {
	cursor: pointer;
	justify-content: center !important;
}
.ag-header-cell-label {
	justify-content: center !important;
}

.text-center:not(.ag-checkbox-input) {
	text-align: center;
}
/* .react-confirm-alert-button-group button.yes-button {
	background-color: var(--clr-primary) !important;
	color: var(--clr-white-01) !important;
} */

/* .react-confirm-alert-button-group button.no-button {
	background-color: var(--clr-transparent);
	color: var(--clr-black-01);
	border: 1px solid var(--clr-primary);
} */

.disabled-icon {
	color: var(--clr-disable-icon);
	/* You can adjust the color for disabled icons */
	cursor: not-allowed;

}

/* Active Icon Style */
.active-icon {
	color: var(--clr-primary-dark);
	/* Set the color for active icons */
	cursor: pointer;
	/* Reset cursor to pointer for active icons */
}


.form-control:focus {
	border-color: none !important;
	outline: 0;
	box-shadow: none !important;
}

.sidebar-toggle-btn {
	display: none;
}

.css-1qhqytf .ps-sidebar-container {
	overflow-y: hidden;
}
/* Media query */
@media only screen and (max-width: 992px) {
	.main-wrapper {
		display: flex !important;
		flex-direction: column !important;
	}


	.css-1qhqytf.ps-broken {
		position: relative !important;
		height: 100%;
		top: 0px;
		z-index: 100;
		left: 0 !important;
	}
	.top-nav-close {
		height: 90px !important;
		transition: all 0.5s ease linear !important;
	}
	.top-nav-open {
		height: 100% !important;
		transition: all 0.5s ease linear !important;
	}
	.sidebar-toggle-btn {
		display: block;
		position: absolute;
		top: 40px;
		width: 34px;
		z-index: 100;
		left: 24px;
	}
	.main-wrapper .view-sidenav-footer {
		text-align: left;
		padding-left: 20px
	}

}

@media only screen and (max-width: 600px) {
	.rbc-toolbar {
		gap: 10px;
	}
	.select-time-slot {
		width: 100% !important;
	}
	.resposnive-grid {
		width: auto !important;
	}
}

.pill-student {
	padding: 5px 5px;
	text-align: center;
	display: inline-block;
	margin: 4px 2px;
	border-radius: 16px;
	border: 1px solid var(--clr-primary);
	/* Add a border */
	background-color: var(--clr-primary-light);
}

/* .radio-align {
	background-color: var(--clr-primary);
	border-color: var(--clr-primary)
} */
.form-check-input:checked {
	background-color: var(--clr-primary) !important;
	border-color: var(--clr-primary-dark) !important;
}
.form-check-input:focus {
	box-shadow: 0 0 0 0.25rem var(--clr-primary-light-02) !important;
}
.ql-toolbar.ql-snow {
	border: 1px solid var(--clr-primary) !important;
}
.ql-container.ql-snow {
	border: none !important;
}
.ql-editor {
	border: 1px solid var(--clr-primary) !important;
	border-top: none !important;
}

.custom-date-picker {
	line-height: 34px;
}

.form-select {
	border-color: var(--clr-primary) !important;
}


.form-select:focus {
	border-color: var(--clr-primary) !important;
	outline: 0;
	box-shadow: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
	color: var(--clr-white-01) !important;
	text-decoration: none;
	background-color: var(--clr-primary) !important;
}

.disabled-link {
	pointer-events: none;
	cursor: not-allowed !important;
}

.slot-pills {
	display: flex;
	min-width: 0;
	background-color: hsl(0, 0%, 90%);
	border-radius: 2px;
	margin: 2px;
	box-sizing: border-box;
}

.rbc-event-label {
	display: none !important;
}

.termsCheckBox input[type="checkbox"] {
	background-color: #b18c93 !important;
	color: white !important;
}

.rating-div {
	border: 1px solid var(--clr-primary);
	border-radius: 5px;
	align-self: center;
	padding: 7px;
}
.rating-div-active {
	border: 1px solid var(--clr-primary-dark);
	border-radius: 5px;
	align-self: center;
	padding: 7px;
	color: #000000;
}
.error-msg {
	color: #7F4850;
	text-align: center;
	display: flex;
	flex: none;
}
.button-dash {
	padding-right: 4.5rem !important;
	padding-left: 1.5rem !important;
}

.text-dash {
	padding-right: 0.5rem !important;
	padding-left: 1rem !important;
}

.verify-btn {
	margin-bottom: 8px;
	padding-top: 4px !important;
	padding-bottom: 4px !important;

}

/* Ag theme Css */
.ag-theme-alpine,
.ag-theme-alpine-dark {
	--ag-alpine-active-color: var(--clr-primary) !important;
	--ag-input-focus-border-color: none !important;
	/* --ag-checkbox-unchecked-color: var(--clr-primary) !important;
	--ag-checkbox-unchecked-background-color: var(var(--clr-primary-light-02)) !important; */
}

.ag-header-cell-resize::after {
	/* content: none; */
	position: absolute;
	z-index: 1;
	display: none !important;
	width: var(--ag-header-column-resize-handle-width);
	height: var(--ag-header-column-resize-handle-height);
	top: calc(50% - var(--ag-header-column-resize-handle-height) * 0.5);
	background-color: var(--ag-header-column-resize-handle-color);

}
/* Big Calander Css */
.rbc-off-range {
	pointer-events: none !important;
}

.form-control:focus {
	/* color: var(--bs-body-color);
	background-color: var(--bs-body-bg); */
	border-color: var(--clr-primary) !important;
	/* outline: 0;
	box-shadow: none !important; */
}

.coach-Img {
	width: 55px;
	height: 55px;
	aspect-ratio: 1;
	object-fit: contain;
	border-radius: 50% !important;
}
.coach-name {
	font-size: 18px;
	font-weight: 600;
}
.coach-rating-div {
	display: flex;
	justify-content: space-between;
}
.coachee-cal {
	margin-left: 0px !important;
}
.rdp-caption_label {
	padding-left: 0px !important;
}
.custom-table {
	text-align: center;
	vertical-align: middle;
	margin-top: 1rem;
}

/* Change the color of the selected date */
.datePicker .react-datepicker__day--keyboard-selected {
	background-color: var(--clr-primary);
}

.datePicker.react-datepicker__day:hover {
	background-color: var(--clr-primary);
}

.datePicker .react-datepicker__day--in-range {
	background-color: var(--clr-primary);
}

.datePicker .react-datepicker__day--in-range:hover {
	background-color: var(--clr-primary);
}

.datePicker .react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover {
	background-color: var(--clr-primary);
}

.datePicker.react-datepicker__day--in-selecting-range:visited {
	background-color: var(--clr-primary);
}

.datePicker.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range {
	background-color: var(--clr-primary);
}

.datePicker .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
	.react-datepicker__month-text--in-range,
	.react-datepicker__quarter-text--in-range,
	.react-datepicker__year-text--in-range) {
	background-color: var(--clr-primary);
}

.datePicker .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
	background-color: var(--clr-primary);
}

/* .datePicker .react-datepicker-wrapper .react-datepicker__input-container input[type="text"] {


	padding: 0 15px;

}
.react-datepicker-wrapper {

	border: none !important;

} */
.datePicker .custom-datepicker-input {
	background-color: var(--clr-transparent);
	border: 1px solid var(--clr-primary);
	border-radius: 5px;
	height: 35px !important;
	padding: 0 15px;

}
.timePicker .custom-datepicker-input {
	background-color: var(--clr-transparent);
	border: 1px solid var(--clr-primary);
	border-radius: 5px;
	height: 35px !important;
	padding: 0 15px;

}

.timePicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
	background-color: var(--clr-primary);
	color: white;

}
.timePicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
	background-color: var(--clr-primary);
	color: white;

}





/* @media (max-width: 767px) {
	.main-content .page-size-wrap {
		flex-direction: column;
	}

	.main-content .page-size {
		margin-bottom: 10px;
	}
} */

@media screen and (max-width: 576px) {
	.ag-theme-alpine .ag-paging-panel {

		/* //flex-direction: column; */
		align-items: flex-start;
	}

	.main-content .page-size-wrap {
		/* flex-direction: column; */
		top: 1%;
	}
}
.disabled-rating {
	pointer-events: none;


}

.disabled-rating .rsr-star {
	cursor: default !important;

}


.react-datepicker-popper[data-placement^=bottom] {
	padding-top: 15px;
	z-index: 11;
}
/* Big Calender css */
.rbc-time-header.rbc-overflowing {
	border-right: 1px solid #ddd;
	/* display: none; */
}

.rbc-header {

	border-bottom: 1px solid var(--clr-agborder) !important;
}
.rbc-month-header {

	background-color: var(--clr-primary);
	color: white;
}

.rbc-month-view {
	border: 1px solid var(--clr-agborder) !important;
}
.rbc-header + .rbc-header {
	border-left: 1px solid var(--clr-agborder) !important;

}
.rbc-month-row + .rbc-month-row {
	border-top: 1px solid var(--clr-agborder) !important;


}
.rbc-day-bg + .rbc-day-bg {
	border-right: 1px solid var(--clr-agborder) !important;
}
.rbc-day-bg {
	border-right: 1px solid var(--clr-agborder) !important;
}

button.rbc-active {

	background-color: var(--clr-primary) !important;
	border-color: var(--clr-primary) !important;
	color: white !important;
}
.rbc-toolbar button {

	border: 1px solid var(--clr-agborder) !important;

}

.rbc-time-content > * + * > * {
	border-left: 1px solid var(--clr-agborder) !important;
}
.rbc-time-view {

	border: 1px solid var(--clr-agborder) !important;

}
.rbc-timeslot-group {
	border-bottom: 1px solid var(--clr-agborder) !important;

}
.rbc-today {
	background-color: #F5D9D5 !important;
}
.rdp-day {
	margin-left: 16px !important;
}