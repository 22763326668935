/* ChatPage.css */

/* Custom style for ConversationList */
.conversation-list {
  background-color: #f0f0f0;
  /* Set your desired background color */
}

/* Custom style for individual conversations */
.conversation-item {
  border-bottom: 1px solid #ccc;
  /* Set your desired border color */
}

/* Custom style for the selected conversation */
.conversation-item.selected {
  background-color: #e6e6e6;
  /* Set your desired background color for selected conversation */
}

.cui-avatar {

  background-color: #D6C5C8;

}
.cui-message-base-primary .cui-message-base-content {
  background-color: #B18C93;
}

/* .cui-recorder {
  display: none;
}
.icon-container {
  display: none;
} */