.auth-layout {
	width: 100%;
	height: 100vh;
	/* background-image: url("../assets/images/bgImg.png"); */
	background-image: linear-gradient(to bottom, rgba(20, 20, 20, 0.52), rgba(10, 10, 10, 0.73)), url("../assets/images/Coach_and_Grow_Login_Background_v1.png");
	background-size: cover;
	background-position: top;
	background-repeat: no-repeat;
	margin: auto;
	padding: 0;
	background-attachment: fixed;

}
.comp-logo-container {
	position: relative;
}
.comp-logo-container .comp-logo {
	height: 100px;
	width: 200px;
	position: absolute;
	top: 2rem;
	left: 5%
}