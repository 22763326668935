/* Primary */
/* .btn-primary {
    color: var(--clr-primary-light);
    background-color: var(--clr-primary);
    font-family: 'Gilroy-Regular', 'Roboto-Regular';
} */

.blueTheme,
.themeCode01 {
	--clr-primary: #8D8DB2 !important;
	--clr-primary-light: #B6B6D6 !important;
	--clr-primary-dark: #383842 !important;
	--clr-secondary: #F4F4FF !important;
	--clr-transparent: transparent !important;
	/* --clr-primary-light-02: #44a9e4 !important; */
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
}

.greenTheme,
.themeCode02 {
	--clr-primary: #8DB2AB !important;
	--clr-primary-light: #B6D6D1 !important;
	--clr-primary-dark: #384042 !important;
	--clr-secondary: #F4FAFF !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
}

.yellowTheme,
.themeCode03 {
	--clr-primary: #B2B08D !important;
	--clr-primary-light: #D6D5B6 !important;
	--clr-primary-dark: #3F4238 !important;
	--clr-secondary: #F9FFF4 !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
}

.orangeTheme,
.themeCode04 {
	--clr-primary: rgb(237, 162, 138) !important;
	--clr-primary-light: #C5979E !important;
	--clr-primary-dark: #3A3331 !important;
	--clr-secondary: #FFD7B6 !important;
	--clr-transparent: transparent !important;
	--clr-white-01: #FFFFFF !important;
	--clr-black-01: #000000 !important;
}

.btn-clr-primary {
	--bs-btn-color: var(--clr-white-01);
	/* --bs-btn-hover-color: var(--clr-primary-dark);
	--bs-btn-disabled-color: var(--clr-white-01); */
	--bs-btn-active-color: var(--clr-white-01);
	--bs-btn-active-background-color: var(--clr-primary);
	background-color: var(--clr-primary);
	--bs-btn-border-color: var(--clr-primary);
}
.btn-clr-primary:hover {
	color: var(--clr-white-01);
	background-color: var(--clr-primary);
	border-color: var(--clr-primary);
}

.btn-clr-transparent {
	--bs-btn-color: var(--clr-primary);
	--bs-btn-hover-color: var(--clr-primary) !important;
	--bs-btn-active-color: var(--clr-black-01);
	--bs-btn-disabled-color: var(--clr-black-01);
	--bs-btn-border-color: var(--clr-primary) !important;
}
.btn .btn-clr-trasparent:hover {
	--bs-btn-border-color: var(--clr-primary) !important;
	--bs-btn-color: var(--clr-primary);
	border-color: var(--clr-primary);
}
.btn:hover {
	border-color: var(--clr-primary)
}
.btn-clr-selected {
	--bs-btn-color: var(--clr-black-01);
	--bs-btn-hover-background-color: var(--clr-primary);
	--bs-btn-hover-color: var(--clr-white-01);
	--bs-btn-active-color: var(--clr-black-01);
	--bs-btn-disabled-color: var(--clr-black-01);
	--bs-btn-border-color: var(--clr-white-01);
	background-color: var(--clr-primary);
}

.btn-clr-trasparent-new {
	--bs-btn-color: var(--clr-primary);
	--bs-btn-hover-color: var(--clr-primary);
	--bs-btn-active-color: var(--clr-primary);
	--bs-btn-disabled-color: var(--clr-black-01);
	--bs-btn-border-color: var(--clr-primary);
}
.btn-clr-trasparent-new:hover {
	--bs-btn-border-color: var(--clr-primary);
}
/* .btn.btn-clr-primary:disabled,
.btn.btn-clr-primary.disabled {
	cursor: not-allowed;
} */
.btn-clr-primary:disabled,
.btn-add:disabled {
	cursor: not-allowed;
	--bs-btn-color: var(--clr-white-01);
	--bs-btn-hover-color: var(--clr-primary);
	--bs-btn-disabled-color: var(--clr-white-01);
	background-color: var(--clr-primary);
	--bs-btn-border-color: var(--clr-secondary);
	border-color: var(--clr-secondary);
}

.disableBtn {
	pointer-events: none;
	--bs-btn-disabled-color: var(--clr-white-01);
	background-color: var(--clr-primary);
	--bs-btn-border-color: var(--clr-secondary);
	border-color: var(--clr-secondary);
}

/* Add Button */
.btn-add {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
	color: var(--clr-white-01);
	background-color: var(--clr-primary);
	border-color: var(--clr-primary);
}

/* .btn.active,
.btn:first-child:active {
	color: var(--clr-primary);
	
	border-color: var(--clr-primary);
} */


.btn-link {
	--bs-btn-color: var(--clr-primary);
	--bs-btn-hover-color: var(--clr-primary);
	--bs-btn-active-color: var(--clr-primary)
}

.btn:focus-visible {
	color: var(--clr-white-01) !important;
	background-color: var(--clr-primary) !important;
	border-color: var(--clr-primary) !important;
	box-shadow: none !important;
}

@media screen and (min-width: 768px) {
	/* .row {
		width: max-content;
	} */

}
.view-btn {
	margin: 10px;
	padding-left: 22px;
	margin-left: 12px;
	text-align: center;
}

.join-btn {
	width: 230px !important;
}
.request-btn {
	margin-top: 50px;
}