.register-container {
	height: 100vh;
	overflow: auto;
}
.register-container > .row {
	margin-top: 5rem;
	padding-bottom: 2rem;
}

.term-container {
	height: 88vh;
	overflow: auto;
}
.term-container > .row {
	margin-top: 5rem;
	padding-bottom: 2rem;
}

.register-container > .row-thank {
	margin-top: 10rem;
	padding-bottom: 2rem;
}

.register-wrap {
	background-color: var(--clr-primary-light);
	padding: 3rem 5rem;
	border-radius: 10px;
	/* border: 1px solid var(--bs-border-color); */
	/* box-shadow: 0px 0px 4px 1px var(--bs-border-color); */
}
.thanks-footer{
	font-weight: 600;
}

.hidden-content {
	display: none;
}

.terms-description{
	padding: 1.5rem;
	/* padding-bottom: .8rem; */
}

.thanks-terms-description{
	padding: .5rem;
}
.thanks-terms-para{
	padding-top: .7em;
}
.register-wrap-term {
	background-color: var(--clr-primary-light);
	border-radius: 10px;
	padding: 1.5em;
	/* border: 1px solid var(--bs-border-color); */
	/* box-shadow: 0px 0px 4px 1px var(--bs-border-color); */
	height: 82vh;
}

.register-wrap-term .row-height {
	height: 80vh;
}

.register-wrap .frm-group {
	margin-bottom: 1.2rem;
}
.register-wrap .frm-group {
	margin-bottom: 1.2rem;
}
.register-wrap .form-label {
	font-family: 'Gilroy-Bold';
	margin-bottom: 0.4rem;
}
.register-wrap .form-control,
.register-wrap .form-control:focus {
	background-color: var(--clr-transparent);
	border-color: var(--clr-grey-03);
}
.register-wrap .form-control::placeholder {
	color: var(--clr-grey-02);
}
.register-wrap .form-select,
.register-wrap .form-select:focus {
	background-color: var(--clr-transparent);
	border-color: var(--clr-grey-03);
}
.register-wrap .frm-heading {
	text-align: center;
}
.register-wrap .frm-subheading {
	text-align: center;
}
.register-wrap .action-lnk {
	color: var(--clr-primary);
}
.register-wrap .action-lnk > svg.icon {
	color: var(--clr-primary);
	margin-left: 5px;
}
.register-wrap .bottom-text {
	font-size: 0.8rem;
	text-align: center;
}

.frm-heading {
	text-align: center;
}

.review-wrap-term {
	background-color: var(--clr-primary-light);
	border-radius: 10px;
	padding: 2.5em;
	/* border: 1px solid var(--bs-border-color); */
	/* box-shadow: 0px 0px 4px 1px var(--bs-border-color); */
	height: 73vh;
}

.left-margin{
	margin-left: 1.5rem;
}


.modal-container {
	background-color: var(--clr-primary-light);
	border-radius: 10px;

	height: 95vh;

}
.offset-lg-3 {
	margin-left: 30%;
}